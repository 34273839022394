import {Layout} from "../Shared/Layout";
import {Button, Col, Form, Input, Row} from "antd";
// @ts-ignore
import logo from "../../Resources/new_logo.svg"
import {useHistory, Link} from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

const Search = () => {
    const history = useHistory()
    const {width} = useWindowDimensions()

    const handleSearch = async (value) => history.push(`/search/${encodeURIComponent(value.search)}`)

    return (
        <Layout>
            <div className={"search"}>
                <Form
                    style={{width: width < 1024 ? "70%" : "30%"}}
                    onFinish={handleSearch}>
                    <Form.Item>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <img src={logo} alt="logo" style={{width: width < 1440 ? "200px" : "300px", marginBottom: "1.25rem"}}/>
                        </div>
                    </Form.Item>
                    <Form.Item name={"search"} rules={[{required: true, message: "Insert a keyword"}]}>
                        <Input type={"search"} placeholder={"Search"}/>
                    </Form.Item>
                    <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item>
                                <Button type={"primary"} htmlType={"submit"} style={{width: "100%"}}>Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    );
};

export default Search;
