// @ts-ignore
import React, {MouseEventHandler, useEffect, useState} from "react";
import {Empty, message, PageHeader, Skeleton, Tooltip, Typography} from "antd";
import {media, orders} from "../Shared/Interface";
import {GET_REQUEST, MEDIA_PATH, ORDERS_PATH} from "../Shared/Requests";
import {ErrorHandler} from "../Shared/ErrorHandler";
import {getContentType} from "../Shared/CommonFunctions";
import {API_INSTANCE} from "../../axios";
import {ArrowLeftOutlined, FileImageTwoTone, FilePdfTwoTone, FileTwoTone, VideoCameraTwoTone} from "@ant-design/icons";
import {useHistory, useLocation} from "react-router-dom";
import {useParams} from "react-router";
import {Layout} from "../Shared/Layout";
import {useAuthState} from "../Shared/AuthContext";
import {AntdIconProps} from "@ant-design/icons/lib/components/AntdIcon";

type MediaProps = {
    sn: string;
    itn: string;
};

/**
 * Icons for each content type
 */
export const mediaTypes = {
    img: (
        <FileImageTwoTone
            twoToneColor="#004a99"
            style={{fontSize: "4rem", width: "100%"}}
        />
    ),
    pdf: (
        <FilePdfTwoTone
            twoToneColor="#004a99"
            style={{fontSize: "4rem", width: "100%"}}
        />
    ),
    video: (
        <VideoCameraTwoTone
            twoToneColor="#004a99"
            style={{fontSize: "4rem", width: "100%"}}
        />
    ),
    other: (
        <FileTwoTone
            twoToneColor="#004a99"
            style={{fontSize: "4rem", width: "100%"}}
        />
    ),
};

const {Paragraph} = Typography;

/**
 * Display all the media of a selphoected item and visualization
 * @returns {React.FC}
 */
const Media = () => {
    const {user}: any = useAuthState();
    const [media, setMedia] = useState<media[]>([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const {sn, itn}: MediaProps = useParams();
    const order_id = useLocation().search ? new URLSearchParams(useLocation().search).get('or') : useLocation().pathname.split("?or=")[1];
    const [orderName, setOrderName] = useState("");

    useEffect(() => {
        getMedia().then();
        order_id && getOrders().then(data => {
            setOrderName(data.filter(d => d["key"] === order_id)[0]["external_key"])
        })
    }, []);

    const getOrders = async () => {
        try {
            return await GET_REQUEST<orders[]>(`${ORDERS_PATH}/${encodeURIComponent(user.key)}`);
        } catch (error) {
            return [];
        }
    };

    const getMedia = async () => {
        setLoading(true);
        try {
            GET_REQUEST(`${MEDIA_PATH}/${sn}`)
                .then(setMedia)
                .catch((error) => console.log(error))
                .finally(() => setLoading(false));
        } catch (error) {
            ErrorHandler(error.response);
        }
    };

    /**
     * Using {@param id} open the media in a new tab
     * @param id selected media id
     */
    const viewMedia = async (id) => {
        setLoading(true);
        try {
            let response = await API_INSTANCE.get(`/presignGetObject?fileKey=${encodeURIComponent(id)}`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("JWT")
                },
            });
            response.status === 201 &&
            window.open(await response.data.data) &&
            setLoading(false);
        } catch (error) {
            message.warning("Media didn't found!");
        }
    };
    
    return (
        <Layout>
            <PageHeader
                title={
                    <div>
                        <ArrowLeftOutlined
                            style={{fontSize: "1rem", marginRight: "1rem"}}
                            onClick={history.goBack}
                        />
                        <span>Product: {itn.includes("or") ? itn.split("?")[0] : itn}</span>
                    </div>
                }
                subTitle={`Click a media to visualize (Order: ${orderName && orderName})`}
            />
            <Skeleton loading={loading} active key={sn}>
                {media.length > 0 ? (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(300px,1fr))",
                            gap: '20px'
                        }}
                    >
                        {media.map((item) => (
                            <MediaItem
                                key={item.key}
                                icon={
                                    !item.thumbnail ? (
                                        mediaTypes[getContentType(item.key)]
                                    ) : (
                                        <img
                                            src={"data:image/jpg;base64," + item.thumbnail}
                                            className="thumbnail"
                                            alt="thumbnail"
                                        />
                                    )
                                }
                                title={item.key.split("/")[item.key.split("/").length - 1]}
                                onClickView={() => viewMedia(item.key)}
                            />
                        ))}
                    </div>
                ) : (
                    <Empty
                        description="No media found"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}
            </Skeleton>
        </Layout>
    );
};

export default Media;


type MediaItemProps = {
    icon: AntdIconProps;
    title: string;
    sn?: string;
    itemType?: string[];
    onClickView?: MouseEventHandler<HTMLElement>;
};
/**
 * Media as an icon Video, Photo or PDF
 * @param icon Media icon
 * @param title Media title
 * @param sn item serial number
 * @param itemType itemType
 * @param onClickView On click open the media on a new tab
 * @returns {React.FC}
 */
export const MediaItem = ({
                              icon,
                              title,
                              sn,
                              itemType,
                              onClickView,
                          }: MediaItemProps) => {
    const name = title.split(".");
    const extension = name[name.length - 1];

    return (
        <div className={"folder"}>
              <span style={{display: "flex", alignItems: "center"}} onClick={onClickView}>
                <div
                    className="thumbnail-background thumbnail-background-style"
                >
                  {sn === null && itemType[0] === null && (
                      <div className="thumbnail-background-tag">
                          <Tooltip overlay={"Item Type: " + itemType[1]} placement={"top"}>
                              <Paragraph
                                  style={{margin: "0", color: "white"}}
                                  ellipsis={{
                                      rows: 3,
                                      expandable: true,
                                  }}
                              >
                                  {itemType[1]}
                              </Paragraph>
                          </Tooltip>
                      </div>
                  )}
                    {icon}
                </div>
                <Tooltip overlay={title} placement={"top"}>
                  <Paragraph
                      strong
                      style={{
                          width: "100%",
                          textAlign: "left",
                          marginTop: "10px",
                      }}
                      ellipsis={{
                          rows: 3,
                          expandable: false,
                          suffix: "." + extension,
                      }}
                  >
                    {name.pop() && name.concat()}
                  </Paragraph>
                </Tooltip>
              </span>
        </div>
    );
};
