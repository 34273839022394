import {Layout} from "./Layout";
import {Button, Result} from "antd";


const NotFound = () => {
    return (
        <Layout>
            <div style={{height: "90vh", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary" onClick={() => history.back()}>Go Back</Button>}
                />
            </div>

        </Layout>
    );
};

export default NotFound;
