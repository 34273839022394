import {useEffect, useState} from "react";
import {
    Layout as L,
    Menu,
    Button,
    Tooltip,
    Divider,
    Modal,
    Dropdown,
} from "antd";
import {
    UserOutlined,
    OrderedListOutlined,
    RightOutlined,
    LeftOutlined,
    LogoutOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    MenuUnfoldOutlined,
    CloseOutlined, SearchOutlined,
    MailOutlined, InfoCircleFilled, ToolFilled,
} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";
import {logout, useAuthDispatch, useAuthState} from "./AuthContext";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import {GET_REQUEST, ORDERS_PATH} from "./Requests";
import {orders} from "./Interface";
// @ts-ignore
import logo from "../../Resources/new_logo.svg"


export const Layout = ({children}) => {
    const {isAdmin, user}: any = useAuthState();
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useAuthDispatch();
    const {width} = useWindowDimensions();
    const h = useHistory();
    const [openInfoModal, setOpenInfoModal] = useState(false)
    const [infoMsg, setInfoMsg] = useState(<></>);
    const [infoBtnVisible, setInfoBtnVisible] = useState(true);
    const [selectedItem, setSelectedItem] = useState([]);

    const confirmLogout = () => {
        Modal.confirm({
            title: "Are you sure you want to logout?",
            icon: <ExclamationCircleOutlined/>,
            okText: "Logout",
            onOk: () => logout(dispatch),
            cancelText: "Cancel",
            centered: true,
        });
    };

    const menuItems = [
        {
            key: "search",
            icon: <SearchOutlined/>,
            title: "Global Search",
            label: "Global Search",
            className: selectedItem.includes("search") ? "selected" : "",
            onClick: () => h.replace("/search")
        },
        {
            key: "orders",
            icon: <OrderedListOutlined/>,
            title: "My Orders",
            label: "My Orders",
            className: selectedItem.includes("orders") ? "selected" : "",
            onClick: () => h.replace("/orders")
        },
    ]

    const subMenuItems = [
        {
            key: "4",
            icon: <MailOutlined/>,
            title: "Ask for help!",
            label: "Ask for help!",
            onClick: () => window.open("mailto:support@vcloudsystems.com?subject=Asking for support on IMD web application&body={Please describe your problem}")
        },
        {
            key: "profile",
            icon: <UserOutlined/>,
            title: "Profile",
            label: "Profile",
            className: selectedItem.includes("profile") ? "selected" : "" + " side-bar-item",
            onClick: () => h.push("/profile/1")
        },
        {
            key: "6",
            icon: <LogoutOutlined/>,
            style: {
                color: "white",
                background: "#cb1324",
                height: "50px",
                display: "flex",
                alignItems: "center"
            }, title: "Logout", label: "Logout", onClick: confirmLogout
        },
    ]

    const toggleCollapsed = () => setCollapsed((state) => !state);

    useEffect(() => {
        setInfoBtnVisible(!(h.location.pathname.split("/").includes("404")))
    }, []);

    const handleInfoModal = () => {
        const path = h.location.pathname.split("/")

        if (path.includes("search")) setInfoMsg(<div>
            <p><i>Here you can search your orders and products.</i></p>
        </div>)
        if (path.includes("search") && path.length > 2) setInfoMsg(<div>
            <p><i>Here you can see search results and you can
                filter them by type(Product or Order)</i></p>
        </div>)

        if (path.includes("orders")) setInfoMsg(<div>
            <p><i>Here you can find all the orders that are assigned to you.</i></p>
            <h4 style={{color: "white"}}>Filter & Sort Orders</h4>
            <p><i>You can filter them by {isAdmin ? "Name, Key or Client" : "Name or Key"} & you can sort them by
                Name</i></p>

        </div>)
        if (path.includes("orders") && path.length > 2) setInfoMsg(<div>
            <p><i>Here you can find all the products of
                order:</i> <b>{path[3]}</b></p>
            <p>"Serviced" tag for indicate that this product has been repaired in the after-sales service</p>
        </div>)
        if (path.includes("products")) setInfoMsg(<div>
            <p><i>Here you can find all the media of the
                product:</i> <b>{path[3]}</b></p>
        </div>)

        if (path.includes("profile")) setInfoMsg(<div>
            <h4 style={{color: "white"}}>Change Password</h4>

            <ol>
                <li>Insert old password</li>
                <li>Insert new password</li>
                <li>Repeat new password</li>
                <span>*if you've forgot your password you can reset it from the login page by clicking "Reset Password"</span>
            </ol>
        </div>)

        if (path.includes("admin")) setInfoMsg(<div>
            <p><i>Here you can find all your clients</i></p>
            <h4 style={{color: "white"}}>All the clients will be added to the application automatically.</h4>
        </div>)
        if (path.includes("admin") && path.length > 4) setInfoMsg(<div>
            <p><i>All the users you've created for the client:</i> <b>{path[4]}</b></p>

            <h4 style={{color: "white"}}>Create new user</h4>
            <ol>
                <li>Click "Add new user/s"</li>
                <li>Insert user's email</li>
            </ol>

            <h4 style={{color: "white"}}>Create new users</h4>
            <ol>
                <li>Click "Add new user/s"</li>
                <li>Insert user's emails seperated by a new line</li>
                *every new line will take as a new email
            </ol>

            <h4 style={{color: "white"}}>Delete User</h4>
            <ol>
                <li>Click on "Delete"</li>
                <li>Confirm the deletion of user selected</li>
                <span>*user won't be able to access the application.</span>
            </ol>
        </div>)
        if (path.includes("manage_client")) setInfoMsg(<div>
            <h4 style={{color: "white"}}>You can add or remove permission for <i>{h.location.pathname.split("/")[4]}</i> to visualize orders of the added clients.</h4>
            <p>*if this client doesn't have any users you can't add permissions.</p>
        </div>)

        setOpenInfoModal(!openInfoModal)
    }

    useEffect(() => {
        let current = h.location.pathname.split("/")[1]

        "products".includes(current) ? setSelectedItem(["orders"]) : setSelectedItem([current])
    }, []);

    return (
        <UserValidity>
            {width > 1024 ? (
                <>
                    <Tooltip
                        title={collapsed ? "Expand Menu" : "Collapse Menu"}
                        placement="right"
                    >
                        <Button
                            className="trigger"
                            style={{
                                left: !collapsed && "200px",
                            }}
                            icon={collapsed ? <RightOutlined/> : <LeftOutlined/>}
                            onClick={toggleCollapsed}
                        />
                    </Tooltip>
                    <L className="siteLayout">
                        <L.Sider className={"side-bar"} collapsed={collapsed}>
                            <Menu selectedKeys={selectedItem} items={[...menuItems, isAdmin &&
                            {
                                key: "admin",
                                icon: <SettingOutlined/>,
                                title: "Admin",
                                label: "Admin",
                                className: selectedItem.includes("admin") ? "selected" : "",
                                onClick: () => h.replace("/admin")
                            }]}/>

                            <div className="sidebar-logo">
                                <Menu selectedKeys={selectedItem} items={subMenuItems}/>

                                <Divider style={{margin: "10px 0"}}/>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <img src={logo} alt="logo" width={"80%"}/>

                                </div>
                            </div>
                        </L.Sider>
                        <L.Content className={"main-content"}> {children} </L.Content>
                    </L>
                </>
            ) : (
                <>
                    <L.Header id="mobile">
                        <div onClick={() => h.push("/orders")} className="logo">
                            <img src={logo} alt="logo"/>
                        </div>
                        <Dropdown
                            className={"dropdown-icon"}
                            overlay={<Menu id="mobile-menu" selectedKeys={selectedItem}
                                           items={[...menuItems,isAdmin &&
                                           {
                                               key: "admin",
                                               icon: <SettingOutlined/>,
                                               title: "Admin",
                                               label: "Admin",
                                               className: selectedItem.includes("admin") ? "selected" : "",
                                               onClick: () => h.replace("/admin")
                                           } , ...subMenuItems]}/>}
                            trigger={["click"]}
                        >
              <span onClick={toggleCollapsed}>
                {!collapsed ? <MenuUnfoldOutlined/> : <CloseOutlined/>}
              </span>
                        </Dropdown>
                    </L.Header>
                    <L.Content className={"main-content"} style={{paddingTop: "10vh"}}>
                        {children}
                    </L.Content>
                </>
            )}

            <div className={"info"} onMouseEnter={handleInfoModal}>
                <Tooltip title={() => {
                    return (<>{infoMsg && infoMsg}</>)
                }}
                         placement={"bottomRight"}
                >
                    {infoBtnVisible && <InfoCircleFilled/>}
                </Tooltip>
            </div>
        </UserValidity>
    );
};


const MenuItem = ({icon, path, text}): JSX.Element => {
    const history = useHistory();
    const current = history.location.pathname.split("/")[1]

    return (
        <>
            <Menu.Item
                key={text}
                className={
                    (
                        path.includes(current)
                        || ("products".includes(current) && path.includes("orders")) ? "selected" : ""
                    ) + " side-bar-item"
                }
                icon={icon}
            >
                <Link to={path}>{text}</Link>
            </Menu.Item>
        </>
    );
};

export const Loader = () => {
    const {width} = useWindowDimensions();

    return (
        <>
            {
                <div className={width > 1024 ? "preloader" : "preloader-mobileTable"}>
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            }
        </>
    );
};

export const UserValidity = ({children}) => {
    const {user}: any = useAuthState();
    const dispatch = useAuthDispatch();
    const history = useHistory();

    useEffect(() => {
        getOrders().then();
    }, []);

    const getOrders = async () => {
        let x = localStorage.getItem("login_at") || user.login_at;
        let loggedIn = new Date(x).getTime();
        let now = new Date().getTime();

        if (x !== "") {
            if ((now - loggedIn) / (1000 * 60 * 60) < 8) {
                let time = new Date().toUTCString();

                localStorage.setItem("login_at", time);

                GET_REQUEST<orders[]>(`${ORDERS_PATH}/${user.key}`)
                    .then((data) => data)
                    .catch((error) => {
                        error.response.status === 401 &&
                        Modal.confirm({
                            title: "Your session has been expired, please log back in!",
                            icon: <ExclamationCircleOutlined/>,
                            okText: "Ok",
                            onOk: () => logout(dispatch) && history.push(`/`),
                            okCancel: false,
                            centered: true,
                        });
                    });
            } else {
                Modal.confirm({
                    title: "Your session has been expired, please log back in!",
                    icon: <ExclamationCircleOutlined/>,
                    okText: "Ok",
                    onOk: () => logout(dispatch) && history.push(`/`),
                    okCancel: false,
                    centered: true,
                });
            }
        }
    };

    return <>{children}</>;
};
